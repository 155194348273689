import { DragEvent, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactFlow, {
	Background,
	BackgroundVariant,
	Controls,
	Position,
	Panel,
	useNodesState,
	useEdgesState,
	addEdge,
	ReactFlowProvider,
	ReactFlowInstance,
	useReactFlow,
	XYPosition,
} from 'reactflow';
import 'reactflow/dist/style.css';

import CustomNode from './CustomNode';
import TooltipNode from './TooltipNode';
import MultiSelectionToolbar from './MultiSelectionToolbar';

import CustomEdge from './CustomEdge';
import CustomInputNode from './CustomInputNode';
import CustomOutputNode from './CustomOutputNode';
import Sidebar from '../flow-sidebar/Sidebar';

const nodeTypes = {
	start: CustomInputNode,
	custom: CustomNode,
	end: CustomOutputNode,
	tooltip: TooltipNode,
};

const defaultNodeStyle = {
	border: '2px solid #ff0071',
	background: 'white',
	borderRadius: 20,
};

const edgeTypes = {
	custom: CustomEdge
}
const flowKey = 'react-flow';
const MyFlow = () => {
	const reactFlowWrapper = useRef<HTMLDivElement>(null)
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();
	const { setViewport } = useReactFlow()

	const onSave = useCallback(() => {
		if (rfInstance) {
			const flow = rfInstance.toObject();
			console.log(flow);
			localStorage.setItem(flowKey, JSON.stringify(flow));
		}
	}, [rfInstance]);

	const onRestore = useCallback(() => {
		const restore = async () => {
			const flow = JSON.parse(localStorage.getItem(flowKey) || '');
			if (flow) {
				const { x = 0, y = 0, zoom = 1 } = flow.viewport;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				setViewport({ x, y, zoom });
			}
		}
		restore();
	}, [setEdges, setNodes, setViewport])

	const updateNodeLabel = useCallback((id: string, label: string) => {
		setNodes((nds) =>
			nds.map((node) => {
				if (node.id === id) {
					node.data = {
						...node.data,
						label: label,
					};
				}
				return node;
			})
		);
	}, [setNodes])

	const onDeleteNode = useCallback((id: string) => {
		rfInstance?.deleteElements({ nodes: [{ id: id }] });
	}, [rfInstance]);

	useEffect(() => {
		// setNodes([
		// 	{
		// 		id: uuidv4(),
		// 		type: 'start',
		// 		data: { label: 'Start Node', toolbarPosition: Position.Top, onChangeLabel: updateNodeLabel, onDelete: onDeleteNode },
		// 		position: { x: 200, y: 0 },
		// 		style: defaultNodeStyle,
		// 	},
		// ])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const onDrop = useCallback(
		(event: DragEvent<HTMLDivElement>) => {
			event.preventDefault();
			console.log('on drop event called.....');
			const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
			const type = event.dataTransfer.getData('application/reactflow') as 'satrt' | 'end' | 'custom';

			// check if the dropped element is valid
			if (typeof type === 'undefined' || !type || !reactFlowBounds) {
				return;
			}
			console.log('on drop event called.....2');
			const position: XYPosition = rfInstance?.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			}) || {x: 0, y: 0};
			const newNode = {
				id: uuidv4(),
				type,
				position,
				data: { label: `${type} node`, toolbarPosition: Position.Top, onChangeLabel: updateNodeLabel, onDelete: onDeleteNode },
				style: defaultNodeStyle
			};

			setNodes((nds) => [...nds, newNode]);
		},
		[onDeleteNode, rfInstance, setNodes, updateNodeLabel]
	);

	const addInputNode = () => {
		const newNode = {
			id: uuidv4(),
			type: 'start',
			data: { label: 'Start Node', toolbarPosition: Position.Top, onChangeLabel: updateNodeLabel, onDelete: onDeleteNode },
			position: {
				x: Math.random() * window.innerWidth - 100,
				y: Math.random() * window.innerHeight - 100,
			},
			style: defaultNodeStyle,
		};
		setNodes((nodes) => [...nodes, newNode]);
	}

	const addOutputNode = () => {
		const newNode = {
			id: uuidv4(),
			type: 'end',
			data: { label: 'Last Node', toolbarPosition: Position.Top, onChangeLabel: updateNodeLabel, onDelete: onDeleteNode },
			position: {
				x: Math.random() * window.innerWidth - 100,
				y: Math.random() * window.innerHeight - 100,
			},
			style: defaultNodeStyle,
		};
		setNodes((nodes) => [...nodes, newNode]);
	}

	const addNewNode = () => {
		const newNode = {
			id: uuidv4(),
			type: 'custom',
			data: { label: 'Decision Node', toolbarPosition: Position.Top, onChangeLabel: updateNodeLabel, onDelete: onDeleteNode },
			position: {
				x: Math.random() * window.innerWidth - 100,
				y: Math.random() * window.innerHeight - 100,
			},
			style: defaultNodeStyle,
		};
		setNodes((nodes) => [...nodes, newNode]);
	}

	const updateEdgeLabel = useCallback((id: string, label: string) => {
		setEdges((edges) =>
			edges.map((edg) => {
				if (edg.id === id) {
					edg.data = {
						...edg.data,
						label: label,
					};
				}
				return edg;
			})
		);
	}, [setEdges])

	const onConnect = useCallback((params: any) => setEdges((els) => addEdge({
		...params,
		id: uuidv4(),
		data: {
			label: 'default label',
			isHovering: false,
			onChangeLabel: updateEdgeLabel
		},
		type: 'custom'
	}, els)), [setEdges, updateEdgeLabel]);

	return (
		<div 
			ref={reactFlowWrapper}
			style={{
				flexGrow: "1",
				height: "100%"
			}}
		>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				className="react-flow-node-toolbar-example"
				onConnect={onConnect}
				minZoom={0.2}
				maxZoom={1}
				fitView
				fitViewOptions={{ padding: 0.5 }}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				onInit={(ref) => setRfInstance(ref)}
				onDragOver={onDragOver}
				onDrop={onDrop}
			>
				<Panel position='top-right'>
					<button style={{display: "none"}} onClick={addInputNode}>Add Start Node</button>
					<button style={{display: "none"}} onClick={addNewNode}>Add Decision Node</button>
					<button style={{display: "none"}} onClick={addOutputNode}>Add Last Node</button>
					<button onClick={onSave}>Save</button>
					<button onClick={onRestore}>Restore</button>
				</Panel>
				<Background variant={BackgroundVariant.Dots} />
				<Controls />
				<MultiSelectionToolbar />
			</ReactFlow>
		</div>
	);
}

const MyFlowExample = () => {
	return (
		
		<div style={{flexDirection: "column", display: "flex", flexFlow: "1", height: "100%"}}>
			<ReactFlowProvider>
				<MyFlow />
				<Sidebar />
			</ReactFlowProvider>
		</div>
		
	)
}

export default MyFlowExample;