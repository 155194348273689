import React, { DragEvent, FC } from 'react';

const Sidebar: FC = () => {
    const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <aside>
            <div style={{ width: "1000px", margin: "0px auto" }}>
                <div style={{ marginBottom: "10px" }}>You can drag these nodes to the pane.</div>
                <div
                    style={{ height: "20px", padding: "4px", border: "1px solid #1a192b", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "grab" }}
                    onDragStart={(event) => onDragStart(event, 'start')}
                    draggable>
                    Start Node
                </div>
                <div
                    style={{ height: "20px", padding: "4px", border: "1px solid #0041d0", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "grab" }}
                    onDragStart={(event) => onDragStart(event, 'custom')}
                    draggable>
                    Decision Node
                </div>
                <div
                    style={{ height: "20px", padding: "4px", border: "1px solid #ff0072", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "grab" }}
                    onDragStart={(event) => onDragStart(event, 'end')}
                    draggable>
                    End Node
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;