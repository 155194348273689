import { FC, memo, useEffect, useRef, useState } from 'react';
import { Position, NodeToolbar, NodeProps, NodeResizer } from 'reactflow';
import CustomHandle from './CustomHandle';

const CustomInputNode: FC<NodeProps> = ({ id, selected, data }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    
    useEffect(() => {
        if(isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing])

    useEffect(() => {
        if(!selected) setIsEditing(false);
    }, [selected])

    return (
        <>
            <NodeResizer minWidth={100} minHeight={30} />
            <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
                <button onClick={ () => {setIsEditing(!isEditing)} }>{ isEditing? 'Save' : 'Edit' }</button>
                <button onClick={ () => data.onDelete(id) }>Delete</button>
            </NodeToolbar>
            <div style={{ padding: '10px 20px' }}>
                {isEditing 
                    ? <input type="text" ref={inputRef} value={data.label} onChange={ (e) => {data.onChangeLabel(id, e.target.value)} } /> 
                    : data.label 
                }
            </div>
            <CustomHandle type="source" position={Position.Bottom} isConnectable={2} />
        </>
    );
};

export default memo(CustomInputNode);