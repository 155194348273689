import React, { FC, useMemo } from 'react';
import { getConnectedEdges, Handle, HandleProps, useNodeId, useStore, getIncomers, useNodes, getOutgoers } from 'reactflow';

const selector = (s: any) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

const CustomHandle: FC<HandleProps & any> = (props) => {
    const { nodeInternals, edges } = useStore(selector);
    const nodeId = useNodeId();
    const nodes = useNodes();

    const isHandleConnectable = useMemo(() => {
        if (typeof props.isConnectable === 'function') {
            const node = nodeInternals.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);
            
            return props.isConnectable({ node, connectedEdges });
        }

        if (typeof props.isConnectable === 'number') {
            const node = nodeInternals.get(nodeId);
            // const connectedEdges = getConnectedEdges([node], edges);
            const connectedEdges = ( props.type === "target" ) ? getIncomers(node, nodes, edges) : getOutgoers(node, nodes, edges);

            return connectedEdges.length < props.isConnectable;
        }

        return props.isConnectable;
    }, [props, nodeInternals, nodeId, edges, nodes]);

    return (
        <Handle {...props} isConnectable={isHandleConnectable}></Handle>
    );
};

export default CustomHandle;