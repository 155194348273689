import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';

const CustomEdge: FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
    data,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [edgePath, labelX, labelY] = getBezierPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition,});
    
    useEffect(() => {
        if(isEditing) inputRef.current?.focus();
    }, [isEditing])

    useEffect(() => {
        setIsEditing(selected ? true : false);
    }, [selected])

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: '#ffcc00',
                        padding: 10,
                        borderRadius: 5,
                        fontSize: 12,
                        fontWeight: 700,
                    }}
                    className="nodrag nopan"
                >
                    {isEditing ? <input ref={inputRef} type='text' onChange={(e) => data.onChangeLabel(id, e.target.value)} value={data.label}/> : <div>{data.label}</div>}
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default memo(CustomEdge);