import React from 'react';
import './App.css';
import MyFlowExample from './components/flow-components/NodeToolbarExample';

function App() {
	return (
		<div className="App">
			<div style={{height: '100vh', width: '100vw'}}>
				<MyFlowExample />
			</div>
		</div>
	);
}

export default App;
